import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import Parser from 'html-react-parser';
import Layout from '../../components/layout';
import mapToComponent from '../../utils/mapToComponent';
import Hero from '../../components/Hero/Hero';
import SEO from '../../components/seo';

export const query = graphql`
  query($slug: String!) {
    allWordpressPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          acf {
            hero_image {
              source_url
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '0px 20px 90px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '200px',
      marginRight: '200px',
    },
  },
}));

const StaticContentPage = ({ data }) => {
  const classes = useStyles();
  const page = data.allWordpressPage.edges[0].node;
  const content = Parser(page.content, {
    replace: (domNode) => mapToComponent(domNode),
  });

  return (
    <Layout>
      <SEO title={page.title} />
      {page && page.acf && page.acf.hero_image && (
        <Hero src={page.acf.hero_image.source_url} />
      )}
      <div className={classes.content}>
        {page.content && <div>{content}</div>}
      </div>
    </Layout>
  );
};

StaticContentPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPage: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            acf: PropTypes.shape({
              hero_image: PropTypes.shape({
                source_url: PropTypes.string,
              }),
            }),
          }),
        }),
      ),
    }),
  }).isRequired,
};

export default StaticContentPage;
